import React from 'react'
import styled from 'styled-components';

const Styles = styled.div`
    .container {
        color: #bababa;
        text-align: center;
        max-width: 100%;
        height: auto;
        margin: 0;

        -webkit-animation: fadein 2s; 
        -moz-animation: fadein 2s; 
         -ms-animation: fadein 2s; 
          -o-animation: fadein 2s; 
             animation: fadein 2s;
    }
    .statement {
        padding-bottom: 10px;
        font-weight: bold;
        font-size: 1.7em;
    }
    .inquire {
        padding-top: 10px;
    }

    a:link, a:visited {
        color: #6b03fc;
    }
    a:hover, a:active {
        color: white;
      }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
    @-ms-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    @-o-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

`;

const blackBg = "https://i.gifer.com/BBMj.gif";

export const About = () => (
    <Styles>
    <div className="container" style={{  
        backgroundImage: `url(${blackBg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }}>
        <h2 className="statement">Cybersecurity focused artist, designer, engineer and digital privacy advocate.</h2>
        <p className="inquire">For inquiries please contact <a href="./">o0o.o0o.0o0.o0o.o0o@protonmail.ch</a></p>
    </div>

    </Styles>
)

/* 
corp spotlight 
https://static01.nyt.com/images/2020/07/30/business/30ontech/30ontech-mobileMasterAt3x-v2.gif
blue static
https://www.metzgereischnitzel.de/wp-content/uploads/2018/04/background-visual-dark.gif
dogs
https://cdn.lowgif.com/small/b012742937575a21-black-aesthetic-tumblr.gif
rain
https://media0.giphy.com/media/RlwF2vFb4y7bDnWvcO/giphy-downsized-large.gif

*/