import React from 'react'
import styled from 'styled-components';


const Styles = styled.div`
    .container {
      background: black;
      color: white;
      text-align: center;
      margin-top: 10%;
    }
    .text {
      font-size: 1.6em;
      letter-spacing: .15em;
      margin: 0 auto;
      overflow: auto;
      white-space: no-wrap;
      border-right: .15em solid green;
      animation: 
      typing 3.6s steps(30, end),
      blinking-cursor .5s step-end infinite;
      overflow: hidden;
    }
    @keyframes typing {
      from { width: 0 }
      to { width: 100% }
    }
    @keyframes blinking-cursor {
      from, to { border-color: transparent }
      50% { border-color: green; max-width: 316px; }
    }
`;

console.log('%c hi ', 'color: red');
console.log('%c nice to see you here! ', 'color: red');

const style1 = [
  'color: white',
  'text-shadow: 2px 2px purple', 
  'background: black', 
  'font-size: 3em',
  'border: 1px solid red',
  'padding: 20px',
  'font-family: monospace'
].join(';');

console.log('%cAll that we see or seem Is but a dream within a dream.', style1);

export const Home = () => (
  <Styles>
  <div className="container">
    <h2 className="text">01101000&nbsp;01101001</h2>
  </div>
  </Styles>
)
