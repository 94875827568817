// Jumbotron is a component for featured content 
import React from 'react';
import { Jumbotron as Jumbo, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as IhLogo } from '../assets/svg-icons/ih-white-black.svg';

//    border-bottom: 1px solid #121212;
const Styles = styled.div`
  .jumbo {
    background-color: black;
    background-size: cover;
    color: #efefef;
    height: 200px;
    position: relative;
    z-index: -2;
    text-align: center;
    padding: 0px;
  }
  .link-wrap {
    display: block;
    position: relative;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    border: none;
  }
  .link-wrap:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: black;
      -webkit-transition: none;
         -moz-transition: none;
              transition: none;
  }
  .link-wrap:hover:after {
    width: 120%;
    background-color: rgba(255,255,255,0);
    
      -webkit-transition: all 1s ease-in-out;
         -moz-transition: all 1s ease-in-out;
              transition: all 1s ease-in-out;
  }
  .ih-logo {
    margin-top: 0px;
    height: 13em;
  }
`;

export const Jumbotron = () => (
  <Styles>
    <a href="/" className="link-wrap">
    <Jumbo fluid className="jumbo">
      <Container>
      <IhLogo className="ih-logo" />
      </Container>
    </Jumbo>
    </a>
  </Styles>
)