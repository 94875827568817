import React from 'react';
import styled from 'styled-components';
// icon images
import abletonlive from './assets/tech/ableton-live.png';
import adobeillustrator from './assets/tech/adobe-illustrator.png';
import aftereffects from './assets/tech/after-effects.png';
import android from './assets/tech/android.png';
import apple from './assets/tech/apple.png';
import applebusinessmanager from './assets/tech/apple-business-manager.png';
import appleconfigurator2 from './assets/tech/apple-configurator-2.png';
import arduino from './assets/tech/arduino.png';
import bash from './assets/tech/bash.png';
import ciscopackettracer from './assets/tech/cisco-packet-tracer.png';
import css3 from './assets/tech/css3.png';
import docker from './assets/tech/docker.png';
import git from './assets/tech/git.png';
import github from './assets/tech/github.png';
import googlesheets from './assets/tech/google-sheets.png';
import html5 from './assets/tech/html5.png';
import ios from './assets/tech/ios.png';
import javascript from './assets/tech/javascript.png';
import jira from './assets/tech/jira.png';
import kali from './assets/tech/kali.png';
import linux from './assets/tech/linux.png';
import macos from './assets/tech/macOS.png';
import netcloud from './assets/tech/netcloud-manager.png';
import nodejs from './assets/tech/node-js.png';
import photoshop from './assets/tech/photoshop.png';
import postman from './assets/tech/postman.png';
import python from './assets/tech/python.png';
import raspi from './assets/tech/raspberry-pi.png';
import reacticon from './assets/tech/react.png';
import virtualbox from './assets/tech/virtual-box.png';
import airwatch from './assets/tech/vmware-airwatch.png';
import vscode from './assets/tech/vscode.png';
import wifipineapple from './assets/tech/wifi-pineapple.png';
import windows from './assets/tech/windows.png';
import wireshark from './assets/tech/wireshark.png';


const Styles = styled.div`
    .container {
        color: #bababa;
        padding-bottom: 200px;

        -webkit-animation: fadein 2s; 
        -moz-animation: fadein 2s; 
         -ms-animation: fadein 2s; 
          -o-animation: fadein 2s; 
             animation: fadein 2s;
    }
    .gallery {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
        grid-gap: 1.3em;
        position: relative;
    }
    .img {
        width: 100%;
        height: auto;
        background-color: black;
    }
    img:hover {
        webkit-filter: blur(4px); /* Chrome, Safari, Opera */
        filter: blur(4px);
        cursor: crosshair;
    }
    .text {
        padding-top: 50px;
        text-align: center;
        font-size: 2em;
    }


    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
    @-ms-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    @-o-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`;


export const Technology = () => (
    <Styles>
    <div className="container">
        <div className="gallery">
        <img className="img" alt="Ableton Live" title="Ableton Live" src={abletonlive} /> 
        <img className="img" alt="Adobe Illustrator" title="Adobe Illustrator" src={adobeillustrator} />
        <img className="img" alt="After Effects" title="After Effects" src={aftereffects} />  
        <img className="img" alt="Android" title="Android" src={android} /> 
        <img className="img" alt="Apple" title="Apple" src={apple} />
        <img className="img" alt="ABM" title="Apple Business Manager" src={applebusinessmanager} />
        <img className="img" alt="Apple Configurator 2" title="Apple Configurator 2" src={appleconfigurator2} />
        <img className="img" alt="Arduino" title="Arduino" src={arduino} />  
        <img className="img" alt="Shell Scripting" title="Shell Scripting" src={bash} /> 
        <img className="img" alt="Cisco Packet Tracer" title="Cisco Packet Tracer" src={ciscopackettracer} />
        <img className="img" alt="CSS3" title="CSS3" src={css3} />  
        <img className="img" alt="Docker" title="Docker" src={docker} />
        <img className="img" alt="Git" title="Git" src={git} />
        <img className="img" alt="Github" title="Github" src={github} />
        <img className="img" alt="Google Sheets" title="Google Sheets" src={googlesheets} /> 
        <img className="img" alt="HTML5" title="HTML5" src={html5} />  
        <img className="img" alt="iOS" title="iOS" src={ios} />  
        <img className="img" alt="JavaScript" title="JavaScript" src={javascript} />  
        <img className="img" alt="Jira" title="Jira" src={jira} />  
        <img className="img" alt="Kali" title="Kali" src={kali} /> 
        <img className="img" alt="Linux <3" title="Linux <3" src={linux} />  
        <img className="img" alt="MacOS" title="MacOS" src={macos} />  
        <img className="img" alt="CradlePoint" title="CradlePoint NetCloud Manager" src={netcloud} /> 
        <img className="img" alt="Node.js" title="Node.js" src={nodejs} /> 
        <img className="img" alt="Photoshop" title="Photoshop" src={photoshop} /> 
        <img className="img" alt="Postman" title="Postman" src={postman} /> 
        <img className="img" alt="Python" title="Python" src={python} /> 
        <img className="img" alt="Raspberry Pi" title="Raspberry Pi" src={raspi} /> 
        <img className="img" alt="React" title="React" src={reacticon} /> 
        <img className="img" alt="Virtual Box" title="Virtual Box" src={virtualbox} /> 
        <img className="img" alt="VMware Airwatch" title="VMware Airwatch" src={airwatch} /> 
        <img className="img" alt="VScode" title="VScode" src={vscode} /> 
        <img className="img" alt="Shinanigans" title="Shinanigans" src={wifipineapple} /> 
        <img className="img" alt="Windows..." title="Windows..." src={windows} /> 
        <img className="img" alt="Wireshark" title="Wireshark" src={wireshark} /> 
        </div>
        <h1 className="text">and more...</h1>
    </div>
    </Styles>
)