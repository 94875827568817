import React from 'react';
import Gallery from "react-photo-gallery";
import { photos } from "./photos";
import styled from 'styled-components';

const Styles = styled.div`
    .container {
        color: #bababa;
        text-align: center;
        max-width: 100%;
        height: auto;
        margin-top: 0;
        padding-bottom: 200px;
        pointer-events: none;

        -webkit-animation: fadein 2s; 
        -moz-animation: fadein 2s; 
         -ms-animation: fadein 2s; 
          -o-animation: fadein 2s; 
             animation: fadein 2s;
    }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
    @-ms-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    @-o-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

`;

export const GalleryPage = () => (
    <Styles>
    <div className="container">
        <Gallery photos={photos} direction={"column"} />
    </div>
    </Styles>
)