import React from 'react';
import styled from 'styled-components';

/* simple sticky footer css */

const Styles = styled.div`
    .footer{
        margin-top: 1rem;
        padding: 1rem;
        background-color: #000000;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        color: #333333;
        font-size: .8em;
        border-top: 1px solid #121212;
  }
`;

export const Footer = () => (
  <Styles>
    <div className="footer">
    <p>©2020-2036 Ian Heil (づ｡◕ ‿ ◕｡)つ*･ﾟ✧  o0o.o0o.0o0.o0o.o0o@protonmail.ch</p>
  </div>
  </Styles>
)