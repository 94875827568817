//GALLERY IMAGES
import blkwhtht from "./assets/gallery/blk-wht-hat-wht-stroke-blk-bg.png";
import dnwhite from "./assets/gallery/dn-white.svg";
import DnHkr from "./assets/gallery/dn-hkr-image-plus.png";
import edcpacked from "./assets/gallery/edcpacked.jpg";
import mscedc from "./assets/gallery/misc_edc.jpg";
import edc_gear from "./assets/gallery/edc_gear.jpg";
import kitopen from "./assets/gallery/kit_open_1.jpg";
import toolkit from "./assets/gallery/toolkit.jpg";
import kitopen2 from"./assets/gallery/kit_open_2.jpg";
import pineappleghost from "./assets/gallery/pineappleghost.jpg";
import somebooks from "./assets/gallery/somebooks.jpg";
import masks from "./assets/gallery/masks.jpg";
import couplesims from "./assets/gallery/couplesims.jpg";
import ihsticker from "./assets/gallery/ihsticker.jpg";
import acoupleiphones from "./assets/gallery/acoupleiphones.jpg";
import liquidpurp from "./assets/gallery/3dliquidpurp.jpg";
import liquidpurple from "./assets/gallery/3dliquidpurple.jpg";
import liquid1 from "./assets/gallery/3dliquid1.jpg";
import bubbles from "./assets/gallery/3dbubbles.jpg";
import bubblelight from "./assets/gallery/3dbubblelight.jpg";
import liquidlight from "./assets/gallery/3dliquidlight.jpg";
import painting0 from "./assets/gallery/painting_0.jpg";
import bashforkbomb from "./assets/gallery/bashforkbomb.png";
import spiderweb from "./assets/gallery/3dspiderweb.jpg";
import wet1 from "./assets/gallery/3d-wet-1.jpg";
import usbdestroy from "./assets/gallery/usbdestroy.jpg";
import liquidart1 from "./assets/gallery/3dliquidart1.jpg";
import chain from "./assets/gallery/3dchain.jpg";
import officestructure from "./assets/gallery/organic_office_structure.jpg";
import barbed2 from "./assets/gallery/barbed2.jpg";
import sheet from "./assets/gallery/3dsheet.jpg";
import globaldatapolice from "./assets/gallery/globaldatapolice.jpg";
import buildingwrap from "./assets/gallery/buildingwrap.jpg";
import securitycam01 from "./assets/gallery/securitycam01.jpg";
import clickbait from "./assets/gallery/clickbait.jpg";
import securitycam1 from "./assets/gallery/securitycam1.jpg";
import entry from "./assets/gallery/entry.jpg";
import questionmarkicon from "./assets/gallery/questionmarkicon.jpg";
import exitstrategy from "./assets/gallery/exitstrategy.jpg";
import barbedbags from "./assets/gallery/barbedbags.jpg";
import chainfloor from "./assets/gallery/chainfloor.jpg";
import barbed1 from "./assets/gallery/barbed1.jpg";
import holocopyright from "./assets/gallery/holocopyright.jpg";
import holorainbow from "./assets/gallery/holorainbow.jpg";
import holoshards from "./assets/gallery/holoshards.jpg";
import digipainting01 from "./assets/gallery/digi_painting_01.jpg";
import securitycam from "./assets/gallery/securitycam.jpg";
import blackportal from "./assets/gallery/black_portal.jpg";
import happyguy from "./assets/gallery/happyguy.jpg";
import forbidden from "./assets/gallery/forbidden.jpg";
import roundcam from "./assets/gallery/round-cam.jpg";
import hallway from "./assets/gallery/halway.jpg";
import gdplogoopt from "./assets/gallery/globaldatapolice_logo_opt.jpg";
import sterilebasement from "./assets/gallery/sterilebasement.jpg";
import dna from "./assets/gallery/dna.jpg";
import unknown1 from "./assets/gallery/unknown1.jpg"
import fedseallight from "./assets/gallery/fedseallight.jpg";
import flames1 from "./assets/gallery/flames-1.jpg";
import waterfal from "./assets/gallery/waterfal.jpg";
import flametip from "./assets/gallery/flametip.jpg";
import isitart from "./assets/gallery/isitart.jpg";
import worldfire from "./assets/gallery/worldfire.jpg";
import blankpage from "./assets/gallery/blankpage.jpg";
import mesh from "./assets/gallery/mesh.jpg";
import batboyeye from "./assets/gallery/batboyeye.jpg";
import foam from "./assets/gallery/foam.jpg";
import statueart from "./assets/gallery/statueart.jpg";
import atenrot from "./assets/gallery/aten_rot_icon_opt.png";
import papershred from "./assets/gallery/papershred.jpg";

export const photos = [
    {
        src: blkwhtht,
        width: 3,
        height: 3,
    },
    {
        src: dnwhite,
        width: 3,
        height: 3,
    },
    {
        src: DnHkr,
        width: 3,
        height: 3,
    },
    {
        src: edcpacked,
        width: 3,
        height: 3
    },
    {
        src: mscedc,
        width: 3,
        height: 3
    },
    {
        src: edc_gear,
        width: 3,
        height: 3
    },
    {
        src: kitopen,
        width: 3,
        height: 3
    },
    {
        src: toolkit,
        width: 3,
        height: 3
    },
    {
        src: kitopen2,
        width: 3,
        height: 3
    },
    {
        src: pineappleghost,
        width: 3,
        height: 3
    },
    {
        src: somebooks,
        width: 3,
        height: 3
    },
    {
        src: masks,
        width: 3,
        height: 3
    },
    {
        src: couplesims,
        width: 3,
        height: 3
    },
    {
        src: ihsticker,
        width: 3,
        height: 3
    },
    {
        src: acoupleiphones,
        width: 3,
        height: 3
    },
    {
        src: liquidpurp,
        width: 3,
        height: 3
    },
    {
        src: liquidpurple,
        width: 3,
        height: 3
    },
    {
        src: liquid1,
        width: 3,
        height: 3
    },
    {
        src: bubbles,
        width: 3,
        height: 3
    },
    {
        src: bubblelight,
        width: 3,
        height: 3
    },
    {
        src: liquidlight,
        width: 3,
        height: 3
    },
    {
        src: painting0,
        width: 3,
        height: 3
    },
    {
        src: bashforkbomb,
        width: 3,
        height: 3
    },
    {
        src: spiderweb,
        width: 3,
        height: 3
    },
    {
        src: wet1,
        width: 3,
        height: 3
    },
    {
        src: usbdestroy,
        width: 3,
        height: 3
    },
    {
        src: liquidart1,
        width: 3,
        height: 3
    },
    {
        src: chain,
        width: 3,
        height: 3
    },
    {
        src: officestructure,
        width: 3,
        height: 3
    },
    {
        src: barbed2,
        width: 3,
        height: 3
    },
    {
        src: sheet,
        width: 3,
        height: 3
    },
    {
        src: globaldatapolice,
        width: 3,
        height: 3
    },
    {
        src: buildingwrap,
        width: 3,
        height: 3
    },
    {
        src: securitycam01,
        width: 3,
        height: 3
    },
    {
        src: clickbait,
        width: 3,
        height: 3
    },
    {
        src: securitycam1,
        width: 3,
        height: 3
    },
    {
        src: entry,
        width: 3,
        height: 3
    },
    {
        src: questionmarkicon,
        width: 3,
        height: 3
    },
    {
        src: exitstrategy,
        width: 3,
        height: 3
    },
    {
        src: barbedbags,
        width: 3,
        height: 3
    },
    {
        src: chainfloor,
        width: 3,
        height: 3
    },
    {
        src: barbed1,
        width: 3,
        height: 3
    },
    {
        src: holocopyright,
        width: 3,
        height: 3
    },
    {
        src: holorainbow,
        width: 3,
        height: 3
    },
    {
        src: holoshards,
        width: 3,
        height: 3
    },
    {
        src: digipainting01,
        width: 3,
        height: 3
    },
    {
        src: securitycam,
        width: 3,
        height: 3
    },
    {
        src: blackportal,
        width: 3,
        height: 3
    },
    {
        src: happyguy,
        width: 3,
        height: 3
    },
    {
        src: forbidden,
        width: 3,
        height: 3
    },
    {
        src: roundcam,
        width: 3,
        height: 3
    },
    {
        src: hallway,
        width: 3,
        height: 3
    },
    {
        src: gdplogoopt,
        width: 3,
        height: 3
    },
    {
        src: sterilebasement,
        width: 3,
        height: 3
    },
    {
        src: dna,
        width: 3,
        height: 3
    },
    {
        src: unknown1,
        width: 3,
        height: 3
    },
    {
        src: fedseallight,
        width: 3,
        height: 3
    },
    {
        src: flames1,
        width: 3,
        height: 3
    },
    {
        src: waterfal,
        width: 3,
        height: 3
    },
    {
        src: flametip,
        width: 3,
        height: 3
    },
    {
        src: isitart,
        width: 3,
        height: 3
    },
    {
        src: worldfire,
        width: 3,
        height: 3
    },
    {
        src: blankpage,
        width: 3,
        height: 3
    },
    {
        src: mesh,
        width: 3,
        height: 3
    },
    {
        src: batboyeye,
        width: 3,
        height: 3
    },
    {
        src: foam,
        width: 3,
        height: 3
    },
    {
        src: statueart,
        width: 3,
        height: 3
    },
    {
        src: atenrot,
        width: 3,
        height: 3
    },
    {
        src: papershred,
        width: 3,
        height: 3
    },

]

/* 
    {
        src: require("./assets/gallery/disclosure_close.png"),
        width: 3,
        height: 3
    },
    {
        src: require("./assets/gallery/facebook_grey.png"),
        width: 3,
        height: 3
    },
    {
        src: require("./assets/gallery/disclosure_open.png"),
        width: 3,
        height: 3
    },

*/